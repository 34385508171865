<template>
  <div>
    <div class="text-h3 font-weight-bold text-center mt-10">PARCEIROS</div>
    <div class="d-flex flex-wrap justify-center mt-4">
      <div v-for="(partner, i) in partners" :key="i" class="shrink">
        <v-card class="mx-4 my-2" width="320px" height="370px">
          <div class="d-flex flex-column fill-height">
            <v-card-title class="font-weight-bold">
              <div class="d-flex align-center justify-center" style="width: 100%; height: 100px">
                <v-img
                  v-if="partner.image"
                  :src="partner.image" contain
                  height="100%"
                  :width="partner.name ? '100px':'100%'"
                />
                <div v-if="partner.name" class="flex-grow-1 text-h5 font-weight-medium" style="word-break: normal">
                  {{ partner.name }}
                </div>
              </div>
            </v-card-title>
            <v-card-text class="text-justify pb-0" style="text-indent: 10px;">
              {{ partner.description }}
            </v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn outlined small class="ma-3" :href="partner.link" target="_blank">
                Saiba Mais
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partners",
  data: () => ({
    partners: [
      {
        name: "Tarefas",
        image: undefined,
        link: "https://tarefas.cnt.br/",
        description: "Gerencie o trabalho da sua Equipe em só lugar, acompanhando os Processos de cada Tarefa, desemprenho Individual, registrando todas ações realizadas e muito mais. Aumente a produtividade do seu Negócio!",
      },
      {
        image: require('@/assets/logo-malha-fina.png'),
        link: "https://malhafina.cnt.br/",
        description: "Uma ferramenta exclusiva para Contadores, que auxilia na Análises de Caixa das Declarações de Imposto de Renda de forma Automática, Segura e Sem Limite de Análises.",
      },
      {
        name: "Questor Sistemas",
        image: require('@/assets/questorSistemas.png'),
        link: "https://questor.com.br/",
        description: "Há 36 anos inovando em tecnologia de resultado. Objetivando potencializar a capacidade humana de tomada de decisão, acreditamos na competência com paixão e ética.",
      },
      {
        image: require('@/assets/ITCNET.png'),
        link: "https://itcnet.com.br/",
        description: "Consultoria nas áreas Fiscal, Contábil, Trabalhista e Previdenciária e disponibilização de conteúdos online relacionados a nossa área de atuação, tais como: legislações, artigos, matérias e informações, desenvolvidos para atender às demandas do mercado em que atuamos.",
      },
      // {
      //   image: require('@/assets/iopoint.png'),
      //   link: "https://iopoint.com.br/",
      //   description: "Somos Especialistas em Ponto Digital, viemos para modernizar o RH, homologados pela portaria 373 do Ministério do Trabalho e Emprego e atendemos a todas as jornadas de trabalhos previstas na CLT, realizamos cálculos de horas extras, adicional noturno e o melhor, temos a opção do banco de horas conforme a MP 927 do poder executivo.",
      // },
    ],
  }),
}
</script>