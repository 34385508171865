<template>
  <v-container>
    <v-row no-gutters>
      <v-col v-for="(data, i) in allServices" :key="i" cols="12" sm="4" md="4" lg="4" xl="4">
        <div class="d-flex justify-center flex-column pt-4">
          <div class="text-center">
            <v-icon size="150" color="light-blue darken-2">
              {{ data.icon }}
            </v-icon>
          </div>
          
          <div class="text-center font-weight-bold text-h5">
            {{ data.title }}
          </div>
          <div class="px-4">
            <div
              v-for="(item, j) in data.services" :key="j"
              class="mt-3 text-center"
              style="line-height: 1.1"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>  
  </v-container>
</template>

<script>
export default {
  name: "Services",

  data: () => ({
    allServices: [
      {
        title: "Constituição de Empresas", icon: "mdi-domain",
        services: [
          "Firma Individual",
          "EIRELI",
          "Sociedade Empresária",
          "Sociedade Anônima",
          "Entidades",
          "Sociedade de propósito específico",
          "Loteamentos PF equiparadas e PJ",
          "Holding Patrimonial",
          "Holding de Participações",
        ],
      },
      {
        title: "Escrituração Contábil", icon: "mdi-text-box-outline",
        services: [
          "Escrituração de Notas Fiscais",
          "Conferência da Emissão das Notas Fiscais",
          "Comunicação e Correção de Falhas Verificadas",
          "Acompanhamento das Alterações",
          "Emissão dos Livros Fiscais",
          "Escrituração dos Estoques",
          "Apuração dos Tributos a Recolher",
          "Emissão das Guias de Recolhimento",
          "Assessoria",
        ],
      },
      {
        title: "Recursos Humanos", icon: "mdi-account-group-outline",
        services: [
          "Controle e Execução de Rotinas Trabalhistas",
          "Execução da Admissão e Demissão de Empregados",
          "Emissão de Envelopes de Pagamento de Salários",
          'Controle de Emissão de Recibos de "Pro-labore"',
          "Emissão de Folha de Pagamentos de Salários",
          "Emissão das Guias de Recolhimento das Contribuições sobre a Folha",
          "Emissão de Relatórios Complementares (RAIS, GFIP, CAGED)",
          "Controle de Contrato de Experiência",
          "Controle do Período Aquisitivo de Férias",
          "Acompanhamento nas Alterações Legais",
          "Assessoria",
        ],
      },
    ],
  }),
};
</script>
