<template>
  <v-dialog v-model="showing" max-width="500px">
    <v-card >
      <v-card-title class="d-flex justify-space-between align-center headline white--text grey darken-3">
        <div>Links Úteis - Contábeis</div>        
          <v-btn icon @click="showing = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <div style="height: 400px; overflow-y: scroll">
        <div v-for="data in allLinks" :key="data.title" class="amber darken-1">
          <div class="text-caption text-center pa-0 font-weight-bold amber darken-1">
            {{data.title}}
          </div>
          
          <v-list>
            <v-list-item v-for="(item, i) in data.links"
              :key="i"
              class="text-body-2"
              :href="item.link"
              target="_blank"
              >
              {{ item.title }}
              <v-icon class="ml-2" small>mdi-open-in-new</v-icon>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    data: () => ({
      showing: false,
      allLinks: [
        { title: 'FEDERAIS',
          links: [
            { title: "Receita Federal", link: "http://idg.receita.fazenda.gov.br/" },
            { title: "INSS", link: "https://www.inss.gov.br/" },
            { title: "Simples Nacional", link: "http://www8.receita.fazenda.gov.br/simplesnacional/" },
          ], 
        },
        { title: 'PARANÁ',
          links: [
            { title: "ICMS do Estado do Paraná", link: "http://www.sefanet.pr.gov.br/dados/sefadocumentos/106201206080.pdf" },
            { title: "Regulamento do ISS de Cascavel", link: "http://www.cascavel.pr.gov.br/secretarias/sefin/subpagina.php?id=203" },
            { title: "SEFAZ - Paraná", link: "http://www.fazenda.pr.gov.br/" },
            { title: "Junta Comercial do Estado do Paraná", link: "http://www.juntacomercial.pr.gov.br/" },
          ], 
        },
        { title: 'RIO GRANDE DO SUL',
          links: [
            { title: "ICMS do Estado do Rio Grande do Sul", link: "http://www.legislacao.sefaz.rs.gov.br/Site/Search.aspx?&CodArea=3&CodGroup=61" },
            { title: "SEFAZ - Rio Grande do Sul", link: "http://www.sefaz.rs.gov.br/" },
            { title: "Junta Comercial do Estado do Rio Grande do Sul", link: "http://www.jucergs.rs.gov.br/" },
          ],
        },
        { title: 'SANTA CATARINA',
          links: [
            { title: "SEFAZ - Santa Catarina", link: "http://www.sef.sc.gov.br/" },
            { title: "Junta Comercial do Estado de Santa Catarina", link: "http://www.jucesc.sc.gov.br/" },
            { title: "ICMS do Estado de Santa Catarina", link: "http://www.sef.sc.gov.br/servicos-orientacoes/diat/icms-gest%C3%A3o" },
          ], 
        },      
      ],
    }),
  methods: {
    open() {
      this.showing = true;
    }
  }
}
</script>