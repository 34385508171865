<template>
  <div v-scroll="onScroll">
    <div>
      <Carousel ref="home" />
    </div>
    <div class="amber lighten-1 py-10">
      <p
        class="text-center text-h4 font-weight-black grey--text text--darken-4 pt-7"
        ref="about"
      >
        QUEM SOMOS
      </p>
      <div class="d-flex justify-center">
        <div style="max-width: 600px" class="text-center text-body-1 grey--text text--darken-3 px-2">
          <p class="mt-2">
            Formado por profissionais com vasta experiência em contabilidade,
            nosso escritório está apto a atender de forma séria e profissional
            qualquer tamanho de empresa sob qualquer demanda contábil.
          </p>
          <p>
            Sempre inovando, respeitando a ética profissional e com isso
            apresentando serviços e atendimento com qualidade, conquistando cada
            vez mais a credibilidade do mercado e a confiança dos clientes na
            entrega de excelentes resultados.
          </p>
          <p>
            Estamos continuamente pesquisando o mercado com suas necessidades e
            expectativas, investimos em conhecimento e tecnologia para acompanhar
            todas as mudanças contábeis e a legislação brasileira para que você
            possa ter tranquilidade em focar no seu negócio.
          </p>
        </div>
      </div>
    </div>
    <div class="py-10" ref="services">
      <div class="text-center text-h3 font-weight-black px-4 pt-5 ma-0">
        SERVIÇOS
      </div>
      <Services />
    </div>
    <div class="grey lighten-3">
      <v-container ref="units">
        <div class="text-h3 font-weight-bold text-center my-4">
          NOSSAS UNIDADES
        </div>
        <v-row>
          <v-col class="my-5" cols="12" md="6">
            <h3>Matriz - São Miguel do Oeste</h3>
            <p class="ma-0 pa-0">
              <strong>Endereço: </strong> R. Santos Dumont, 134, Sala 101 - Centro,
              São Miguel do Oeste - SC
            </p>
            <div class="pa-0 ma-0 d-flex">
              <strong class="mr-1">Telefone:</strong>
              (49) 3622-2139
              <div class="ml-5">
                <strong>WhatsApp: </strong>
                (49) 3645-0254
              </div>
            </div>
            <v-card class="mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d445.4238345572093!2d-53.519971441490064!3d-26.73190634692719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94fa5c6019e4ec9d%3A0xbc0993a53d30d764!2sR.%20Santos%20Dumont%2C%20134%20-%20Centro%2C%20S%C3%A3o%20Miguel%20do%20Oeste%20-%20SC%2C%2089900-000!5e0!3m2!1spt-BR!2sbr!4v1613399101005!5m2!1spt-BR!2sbr"
                width="100%"
                height="300"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
                class="ma-0 pa-0 d-block"
              />
            </v-card>
          </v-col>
          <v-col class="my-5" cols="12" md="6">
            <h3>Filial - Guaraciaba</h3>
            <p class="ma-0 pa-0">
              <strong>Endereço: </strong>R. Ademar de Barros, 59, Sala 03 -
              Centro, Guaraciaba - SC
            </p>
            <div class="pa-0 ma-0 d-flex">
              <strong class="mr-1">Telefone:</strong>
              (49) 3645-0254
              <div class="ml-5">
                <strong>WhatsApp:</strong>
                (49) 3645-0254
              </div>
            </div>
            <v-card class="mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d222.97047071044142!2d-53.520728682394626!3d-26.599532515317673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94fa6662a9ce8dff%3A0x5049bfd578f6a548!2sR.%20Pref.%20Ademar%20de%20Barros%2C%2059%20-%20Guaraciaba%2C%20SC%2C%2089920-000!5e0!3m2!1spt-BR!2sbr!4v1733843823710!5m2!1spt-BR!2sbr"
                width="100%"
                height="300"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
                class="ma-0 pa-0 d-block"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div ref="links">
      <v-container align="center">
        <v-row class="my-4">
          <v-col cols="12" sm="6" align="center">
            <v-card width="410" height="200" @click="$refs.links01.open()">
              <v-img
                src="@/assets/image01.jpg"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.9)"
                width="100%"
                height="100%"
              >
                <div
                  class="text-h4 pa-4 font-weight-medium d-flex justify-center align-center amber--text text--darken-1"
                  style="width: 100%; height: 100%"
                >
                  Links Úteis
                  <v-icon color="amber darken-1" class="ma-1" size="50">mdi-open-in-new</v-icon>
                </div>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex justify-center">
            <v-card height="200" width="410" @click="$refs.links02.open()">
              <v-img
                src="@/assets/image02.png"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.9)"
                width="100%"
                height="100%"
              >
                <div
                  class="text-h4 pa-4 font-weight-medium d-flex justify-center align-center amber--text text--darken-1"
                  style="width: 100%; height: 100%"
                >
                  Links Contábeis
                  <v-icon color="amber darken-1" class="ma-1" size="50">mdi-open-in-new</v-icon>
                </div>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-divider class="grey darken-2" />
    <div ref="partners">
      <Partners />
    </div>
    <div class="amber darken-1" ref="contact">
      <v-container>
        <v-row>
          <v-col style="line-height: 0.8">
            <p class="text-h4 font-weight-black mb-2">CONTATO</p>
            <div class="d-flex">
            <div class="mr-5">
            <p class="mb-1 text-subtitle-2">Matriz - São Miguel do Oeste</p>
            <p class="ma-0 pa-0">
              <strong>
                <v-icon color="black">mdi-phone</v-icon>
                (49) 3622-2139
              </strong>
            </p>
            <v-btn text class="pa-0" href="https://api.whatsapp.com/send?phone=554936222139" target="_blank">
              <strong>
                <v-icon color="black">mdi-whatsapp</v-icon>
                (49) 3622-2139
              </strong>
            </v-btn>
            </div>
            <div>
            <p class="mb-1 text-subtitle-2">Filial - Guaraciaba</p>
            <p class="ma-0 pa-0">
              <strong>
                <v-icon color="black">mdi-phone</v-icon>
                (49) 3645-0254
              </strong>
            </p>
            <v-btn text class="pa-0" href="https://api.whatsapp.com/send?phone=554936450254" target="_blank">
              <strong>
                <v-icon color="black">mdi-whatsapp</v-icon>
                (49) 3645-0254
              </strong>
            </v-btn>
            </div>
            </div>
            <p class="my-2">
              <v-icon color="black">mdi-email</v-icon> contato@rudinei.cnt.br
            </p>
            
            <div class="text-h4 font-weight-black">Siga nossas Redes Sociais</div>
            <v-btn icon href="https://www.facebook.com/RudineiContabilidade" target="_blank"><v-icon large color="white">mdi-facebook</v-icon></v-btn>
            <v-btn icon href="https://twitter.com/rudinei_ctb" target="_blank"><v-icon large color="white">mdi-twitter</v-icon></v-btn>
          </v-col>
          <v-col>
            <p class="mt-5 mb-2 text-h4 font-weight-black">ENDEREÇOS</p>
            <p class="mb-1">Matriz - São Miguel do Oeste</p>
            <p class="mb-3">
              <v-icon color="black">mdi-map-marker</v-icon>
              <strong>R. Santos Dumont, 134, Sala 101 - Centro </strong>
            </p>
            <p class="mb-1">Filial - Guaraciaba</p>
            <p>
              <v-icon color="black">mdi-map-marker</v-icon>
              <strong>R. Ademar de Barros, 59, Sala 03 - Centro</strong>
            </p>
            <!-- <p class="text-h4 font-weight-black">ENVIE UMA MENSAGEM</p>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  dark
                  color="black"
                  dense
                  solo
                  label="Nome*"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dark
                  color="black"
                  dense
                  solo
                  label="Email*"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dark
                  color="black"
                  solo
                  label="Mensagem*"
                  hide-details
                />
              </v-col>
              <div align="right" style="width: 100%">
                <v-btn color="success" class="mx-2"> Enviar </v-btn>
              </div>
            </v-row> -->
          </v-col>
        </v-row>
      </v-container>
      <v-card-text class="py-2 white--text text-center black">
        Todos os direitos reservados |
        <v-icon dark small>mdi-copyright</v-icon> 2021 | Rudinei Contabilidade &
        Consultoria
      </v-card-text>
    </div>
  
    <DialogLinks01 ref="links01"/>
    
    <DialogLinks02 ref="links02"/>
  
  </div>
</template>

<script>
import Carousel from "../components/Carousel.vue";
import DialogLinks01 from "@/components/DialogLinks01.vue";
import DialogLinks02 from "../components/DialogLinks02.vue";
import Services from "../components/Services.vue";
import Partners from "../components/Partners.vue";

export default {
  name: "Home",
  components: {
    Carousel,
    DialogLinks01,
    DialogLinks02,
    Services,
    Partners,
  },
  data: () => ({
    scrolling: false,
    naturalScroll: false,
    sections: [
      "home",
      "about",
      "services",
      "units",
      "links",
      "partners",
      "contact",
    ],
  }),
  watch: {
    "$route.name"(routeName) {
      if (this.naturalScroll) {
        return;
      }
      this.scrolling = true;
      const duration = 1000;
      this.$vuetify.goTo(this.$refs[routeName], { duration });
      setTimeout(() => {
        this.scrolling = false;
      }, duration + 100);
    },
  },
  methods: {
    onScroll() {
      if (this.scrolling) return;
      const middle = window.innerHeight / 2;
      let currentRef;
      this.sections.forEach((ref) => {
        let el = this.$refs[ref];
        if (el.$el) el = el.$el;
        const bb = el.getBoundingClientRect();
        if (bb.top <= middle) {
          currentRef = ref;
        }
      });
      if (this.$route.name != currentRef) {
        this.naturalScroll = true;
        this.$router.replace({ name: currentRef });
        this.$nextTick(() => {
          this.naturalScroll = false;
        });
      }
    },
  },
};
</script>
