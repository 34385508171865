<template>
<v-dialog v-model="showing" max-width="500px">
  <v-card>
    <v-card-title class="d-flex align-center justify-space-between headline white--text grey darken-3">
      <div>Links Úteis</div>
        <v-btn icon @click="showing = false"
          ><v-icon color="white">mdi-close</v-icon></v-btn
        >      
    </v-card-title>

    <v-text-field
      v-model="searchField"
      solo class="ma-2"
      prepend-inner-icon="mdi-magnify"
      label="Buscar"
      hide-details
    />
    
    <!-- <v-list class="ma-1"> -->
    <v-virtual-scroll
     :bench="4"
     :items="filteredUseful"
     height="400px"
     item-height="64px"
    >
      <template #default="{ item }">
        <v-list-item :key="i" :href="item.link" target="_blank" class="text-body-2">
            {{ item.title }}
            <v-icon class="ml-2" small>mdi-open-in-new</v-icon>
        </v-list-item>
      </template>
    </v-virtual-scroll>
    <!-- </v-list> -->
    
  </v-card>
</v-dialog>
</template>
<script>
export default {
  data: () => ({
    showing: false,
    searchField: "",
    useful: [
      { title: "Caixa Econômica Federal", link: "http://www.caixa.gov.br/Paginas/home-caixa.aspx" },
      { title: "Cálculo e Impressão de DARF", link: "http://idg.receita.fazenda.gov.br/orientacao/tributaria/pagamentos-e-parcelamentos/darf-calculo-e-impressao-programa-sicalc-1/programa-para-calculo-e-emissao-de-darf-on-line-de-tributos-e-contribuicoes-federais-exceto-contribuicoes-previdenciarias" },
      { title: "Cálculo e Impressão de GPS", link: "http://www2.dataprev.gov.br/PortalSalInternet/faces/pages/calcContribuicoesEmpresasEOrgaosPublicos/inicio.xhtml" },
      { title: "Calculo ST-SC on-line", link: "https://tributario.sef.sc.gov.br/tax.Net/Sat.Fiscalizacao.ST.Web/Fis_CalculoST_V2_EmManutencao.html" },
      { title: "CBO", link: "http://www.mtecbo.gov.br/cbosite/pages/home.jsf" },
      { title: "Certidão da Secretaria da Fazenda Estadual SC", link: "https://tributario.sef.sc.gov.br/tax.NET/Sat.CtaCte.Web/SolicitacaoCnd.aspx" },
      { title: "Certidão de antecedêntes criminais", link: "https://servicos.dpf.gov.br/sinic-certidao/emitirCertidao.html" },
      { title: "Certidão de Falência e Recuperação Judicial (Fórum)", link: "http://esaj.tjsc.jus.br/sco/abrirCadastro.do" },
      { title: "Certidão de Regularidade DPF", link: "https://servicos.dpf.gov.br/pgdwebcertificado/public/pages/empresa/consultarSituacaoEmpresa.jsf" },
      { title: "Certidão Negativa de Débitos Trabalhistas - CNDT", link: "http://www.tst.jus.br/certidao" },
      { title: "Certidão Previdenciária - INSS", link: "https://www.receita.fazenda.gov.br/aplicacoes/atspo/certidao/cndconjuntainter/informanicertidao.asp?tipo=1" },
      { title: "Certidão Simplificada da Jucesc", link: "http://www.jucesc.sc.gov.br/index.php/servicos/certidoes/solicitar-certidao-simplificada" },
      { title: "Certidões Municipais - Betha Sistemas", link: "https://e-gov.betha.com.br/cidadaoweb3/main.faces" },
      { title: "Certidões no âmbito federal:", link: "http://idg.receita.fazenda.gov.br/interface/lista-de-servicos/certidoes-e-situacao-fiscal/certidao-de-regularidade" },
      { title: "Certificado de Regularidade do FGTS", link: "https://www.sifge.caixa.gov.br/Cidadao/Crf/FgeCfSCriteriosPesquisa.asp" },
      { title: "CFC", link: "https://cfc.org.br/" },
      { title: "Códigos da Receita do DARF", link: "http://www.receita.fazenda.gov.br/Aplicacoes/ATSPO/CodigoReceita/default.asp" },
      { title: "Consulta atividades WebCnae", link: "http://www.cnae.ibge.gov.br/" },
      { title: "Consulta Base INPI", link: "https://gru.inpi.gov.br/pePI/servlet/LoginController?action=login" },
      { title: "Consulta Sintegra", link: "http://www.sintegra.gov.br/" },
      { title: "Consulta Validade de NFe", link: "http://www.nfe.fazenda.gov.br/portal/consulta.aspx?tipoConsulta=completa&tipoConteudo=XbSeqxE8pl8=" },
      { title: "Correios", link: "http://www.correios.com.br/" },
      { title: "CRCSC", link: "http://www.crcsc.org.br/" },
      { title: "Declaração do IR Retido na Fonte", link: "http://www.receita.fazenda.gov.br/principal/informacoes/infodeclara/declaradirf.htm" },
      { title: "Declaração Simplificada da PJ", link: "http://www.receita.fazenda.gov.br/principal/informacoes/infodeclara/declarapjsimples.htm" },
      { title: "Declarações Pessoa Jurídica", link: "https://www.receita.fazenda.gov.br/Aplicacoes/SSL/ATRJO/consProcess/Index.asp" },
      { title: "Decore on-line CFC", link: "http://sistemas.cfc.org.br/login" },
      { title: "Decretos Leis", link: "http://www.planalto.gov.br/ccivil/Decreto-Lei/principal_ano.htm" },
      { title: "Decretos não enumerados", link: "http://www.planalto.gov.br/ccivil_03/dnn/quadro/principal.htm" },
      { title: "Departamento da Polícia Federal", link: "http://www.pf.gov.br/" },
      { title: "DIPJ", link: "https://www.receita.fazenda.gov.br/Aplicacoes/SSL/ATRJO/consProcess/Index.asp" },
      { title: "DIRF", link: "https://www.receita.fazenda.gov.br/Aplicacoes/ATCTA/dirf2003/declarantes.App/default.asp" },
      { title: "e-Betha - Livro Eletrônico", link: "https://e-gov.betha.com.br/livroeletronico/" },
      { title: "E-mail Rudinei", link: "https://accounts.google.com/signin/v2/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&service=mail&hd=rudinei.cnt.br&sacu=1&flowName=GlifWebSignIn&flowEntry=AddSession" },
      { title: "Emendas a Constituição", link: "http://www.planalto.gov.br/ccivil_03/Projetos/Quadros/principal2003.htm" },
      { title: "Emissão DARE-SC on-line", link: "https://tributario.sef.sc.gov.br/tax.NET/Login.aspx?ReturnUrl=%2ftax.Net%2ftax.net.emissaodar%2femissaodar_normal.aspx" },
      { title: "Extrato IR - Previdência", link: "https://extratoir.inss.gov.br/irpf01/pages/consultarExtratoIR.xhtml" },
      { title: "FGTS", link: "http://www.mtecbo.gov.br/cbosite/pages/home.jsf" },
      { title: "IBAMA - Serviços on-line", link: "https://servicos.ibama.gov.br/ctf/index2.php" },
      { title: "Inscrição Estadual EI", link: "https://tributario.sef.sc.gov.br/tax.NET/tax.Net.SimplesNacional/sna_CadastrarSIMEIConfirma.aspx" },
      { title: "IOB SPED", link: "http://www.iob.com.br/sped/products-auditor-sped.asp" },
      { title: "IR - Contribuição Unimed", link: "http://sguweb.smo.unimedsc.com.br/sgu-web/index.php?usr=clb" },
      { title: "IR - Pagtos CEF (casa própria)", link: "http://www1.caixa.gov.br/servico/habitacao/index.asp" },
      { title: "IRPF - Consulta Restituição", link: "http://www.receita.fazenda.gov.br/aplicacoes/atrjo/consrest/atual.app/paginas/mobile/restituicaoMobi.asp" },
      { title: "ITC", link: "https://itcnet.com.br/" },
      { title: "MALHA FINA", link: "https://malhafina.cnt.br/" },
      { title: "Medidas Provisórias", link: "http://www.planalto.gov.br/ccivil_03/mpv/principal.htm" },
      { title: "Pedido de Vistoria Bombeiros", link: "http://www.cb.sc.gov.br/index.php?option=com_content&view=category&id=46&layout=blog&Itemid=67" },
      { title: "Procuração eletrônica -RFB", link: "http://www.receita.fazenda.gov.br/Aplicacoes/ATSDR/procuracoesrfb/controlador/controlePrincipal.asp?acao=telaInicial" },
      { title: "Projetos de Leis", link: "http://www.planalto.gov.br/ccivil_03/Projetos/Quadros/principal2003.htm" },
      { title: "R.I.R", link: "http://www.receita.fazenda.gov.br/Legislacao/RIR/default.htm" },
      { title: "REGIN - Jucesc", link: "http://www.jucesc.sc.gov.br/index.php/servicos/regin" },
      { title: "Regulamento do IPI", link: "http://www.receita.fazenda.gov.br/Legislacao/Decretos/Ant2001/Ant1999/decreto2637/default.htm" },
      { title: "Requerimento Jucesc", link: "http://regin.jucesc.sc.gov.br/requerimentoV2/Servicos.aspx" },
      { title: "RFB DBE COLETA WEB", link: "http://www.redesim.gov.br/" },
      { title: "SAT - Santa Catarina", link: "https://tributario.sef.sc.gov.br/tax.NET/Login.aspx?ReturnUrl=%2ftax.net%2fdefault.aspx" },
      { title: "SEF-SC emissão NFA-e", link: "https://tributario.sef.sc.gov.br/tax.net/tax.net.nfe/nfa_emissao.aspx" },
      { title: "Selic mensal RFB", link: "http://idg.receita.fazenda.gov.br/orientacao/tributaria/pagamentos-e-parcelamentos/taxa-de-juros-selic" },
      { title: "Simples Nacional", link: "http://www8.receita.fazenda.gov.br/SimplesNacional/" },
      { title: "Sinco", link: "https://www.receita.fazenda.gov.br/aplicacoes/ssl/atspo/sinco/sincoaux0.asp" },
      { title: "Tarefas", link: "http://tarefas.cnt.br/" },
      { title: "Taxa de Juros (SELIC)", link: "http://www.receita.fazenda.gov.br/Pagamentos/jrselic.htm" },




    ],
  }),
  created() {
    //
  },
  computed: {
    filteredUseful() {
      return this.useful.filter((item) => {
        return item.title.toLowerCase().match(this.searchField.toLowerCase());
      });

    }
      
  },
  methods: {
    open() {
      this.showing = true;
    }
  }
};
</script>