<template>
    <v-carousel
      cycle
      hide-delimiters
      continuous
      height="600"
      :interval="5000"
    >
      <v-carousel-item
        v-for="(item, i) in items1"
        :key="i"
        :src="item.src"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.9)"
      >
        <div
          style="height: 50%"
          class="align-end d-flex justify-center text-center"
        >
          <p class="text-h3 font-weight-medium pa-3 amber--text text--darken-1">
            {{ item.title }}
          </p>
        </div>
        <div class="d-flex justify-center">
          <v-btn
            class="mx-5 black--text"
            color="amber darken-1"
            large
            :to="item.link"
            :href="item.clientPage"
            >{{item.btnText}}</v-btn
          >
        </div>
      </v-carousel-item>
    </v-carousel>
</template>
<script>
export default {
    data: () => ({
        items1: [
      {
        src: require("@/assets/image01.jpg"),
        title: "Conheça os Serviços que prestamos",
        link: { name: "services" },
        btnText: "Saiba Mais"
      },
      {
        src: require("@/assets/NossasUnidades.jpg"),
        title: "Nossas Unidades",
        link: { name: "units" },
        btnText: "Saiba Mais"
      },
      {
        src: require("@/assets/ConverseNossaEquipe.jpg"),
        title: "Converse com a nossa Equipe",
        link: { name: "contact" },
        btnText: "Saiba Mais"
      },
      {
        src: require("@/assets/client-area.jpg"),
        title: "Área do Cliente",
        clientPage: "https://rudineicontabilidade.app.questorpublico.com.br/entrar",
        btnText: "Clique Aqui"
      },
    ],
    }),
}
</script>